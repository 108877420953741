import create from 'zustand'
import { MainService } from './feature/main/Main.service'
import MainTextProvider from './feature/main/texts'
import { AxiosService, ServerMessengerEventData, ServerMessengerService } from './utils/services'
import type { ChatBoatChat as NS } from '@whatsapp/communication'
import { HostCommunication } from './host-communication'
import { configureWhatsapp, loadChat } from './load-aplication-data'
import { useChatStore } from './feature/chat/chat-store'

const texts = MainTextProvider.get()

const axiosService = AxiosService.singleton()
const mainService = MainService.singleton()
const serverMessenger = ServerMessengerService.singleton()

type AlertSeverity = 'error' | 'info' | 'success' | 'warning'

type AlertStore = {
    severity: AlertSeverity
    title?: string
    message?: string
}

type User = {
    domain: string
    username: string
}

export type ApplicationStore = {
    token: string
    attendantName?: string
    language?: string
    provider: string
    expanded: boolean
    fullPage: boolean
    fullPageLink?: string
    authenticating: boolean
    animating: boolean
    unreadMessageCount: number
    alert?: AlertStore
    user?: User
    tickets: NS.Ticket[]
    loadingTickets: boolean
    notifications: ServerMessengerEventData[]
    blockContactChange: boolean
    setBlockContactChange: (b: boolean) => void
    onNotification: (n: ServerMessengerEventData[]) => void
    shrinking: () => void
    onTicketReply: (t: NS.Ticket[]) => void
    setAnimating: (b: boolean) => void
    onCloseAlert: () => void
    authenticate: () => Promise<void>
    onExpand: () => Promise<void>
    onMessageSent: (evt: Event) => void
}

export const useApplicationStore = create<ApplicationStore>((set, get) => ({
    provider: '',
    token: '',
    fullPage: false,
    authenticating: false,
    animating: false,
    unreadMessageCount: 0,
    expanded: false,
    tickets: [],
    notifications: [],
    loadingTickets: false,
    blockContactChange: false,

    setBlockContactChange: function (block) {
        set(() => ({ blockContactChange: block }))
    },

    shrinking: function () {
        set(() => ({ expanded: false }))
    },

    onNotification: function (notifications) {
        const totalOfUnreadMessages = notifications.reduce((a, b) => a + b.quantity, 0)
        set(() => ({ unreadMessageCount: totalOfUnreadMessages, notifications }))

        if (totalOfUnreadMessages > 0) {
            const hostCommunication = HostCommunication.singleton()
            mainService.authorize().then((auth) => {
                const user = { domain: auth.realm, username: auth.userName }
                hostCommunication.requestTickets(user)
            })
        }

        useChatStore.getState().cleanNotification()
    },

    onExpand: async function () {
        const hostCommunication = HostCommunication.singleton()
        const { realm, userName } = await mainService.authorize()
        const user = { domain: realm, username: userName }

        hostCommunication.requestTickets(user)

        configureWhatsapp()

        set(() => ({ expanded: true }))
    },

    onTicketReply: function (tickets) {
        set(() => ({ tickets, loadingTickets: false }))
        loadChat()
    },

    setAnimating: function (animating: boolean) {
        set(() => ({ animating }))
    },

    onCloseAlert: function () {
        set(() => ({ alert: undefined }))
    },

    authenticate: async function () {
        const { token, provider } = get()

        if (!token || !provider) {
            set({ authenticating: false })
            return
        }

        set({ authenticating: true })

        axiosService.authorization = token
        axiosService.provider = provider

        try {
            const { realm, userName } = await mainService.authorize()
            const user = { domain: realm, username: userName }

            set(() => ({ user, authenticating: false }))

            serverMessenger.join(realm, userName)
        } catch (__) {
            set(() => ({
                alert: {
                    severity: 'error',
                    message: texts.ALERT_NON_AUTENTICATED_MESSAGE,
                    title: texts.ALERT_NON_AUTENTICATED_TILE
                }
            }))
        }
    },

    onMessageSent: function (evt: Event) {
        const hostCommunication = HostCommunication.singleton()
        hostCommunication.messageSent(evt)
    }
}))
