import { lodash } from '@syonet/lang'
import { CaughtException, HttpClientAPI } from '@whatsapp/communication'
import { HttpClientService } from './HttpClientService'

// :: Singletons

const httpClientService = HttpClientService.singleton()

// :: Class

export abstract class AbstractBusClientService {
    // :: API

    public static setReloadAction(reloadAction: () => void) {
        httpClientService.setReloadAction(reloadAction)
    }

    public get httpClient(): HttpClientAPI {
        return httpClientService
    }

    protected newSend<REQ, RESP>(path: string) {
        const urlPath = `/${path}`
        return async (request: REQ): Promise<RESP> => {
            const url = new URL(urlPath, httpClientService.endpoint)
            const response = await httpClientService.fetchPost<RESP>(url, 200, request)
            this.raiseUnexpected(response)
            return response
        }
    }

    protected raiseUnexpected(response: unknown) {
        const caught = response as CaughtException
        if (lodash.isNumber(caught.err) && caught.err !== 0 && caught.err !== 200) {
            throw new Error(`${caught.err}: ${caught.msg ?? ''}`)
        }
    }
}
