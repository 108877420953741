/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { DateFns, HttpError } from '@syonet/lang'
import { ChatBoatChat as NS } from '@whatsapp/communication'
import { AbstractBusClientService } from 'src/utils/services'

// :: Contacts

export type ChatContact = Omit<NS.ChatContact, 'createdAt' | 'readAt'> & {
    createdAt: Date
    readAt?: Date
}

export type ChatContactsRequest = NS.ChatContactsRequest
export type ChatContactsResponse = Omit<NS.ChatContactsResponse, 'contacts' | 'selectedContact'> & {
    contacts: ChatContact[]
    selectedContact?: ChatContact
}

// :: Tickets

export type ChatTicket = NS.ChatTicket
export type ChatTicketsRequest = NS.ChatTicketsRequest
export type ChatTicketsResponse = NS.ChatTicketsResponse

// :: TicketAndContacts

export type ContactsAndTicketsResponse = {
    contacts: ChatContactsResponse
    tickets: ChatTicketsResponse
}

export type User = { domain: string; username: string }

export type PreSaleTickesRequest = { account: string; username: string; tickets: NS.Ticket[] }
export type PreSaleTickesResponse = NS.Ticket[]

export class ChatService extends AbstractBusClientService {
    // :: Static

    private static INSTANCE = new ChatService()

    public static singleton() {
        return ChatService.INSTANCE
    }

    // :: API

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    public async handlePreSaleTickets(request: PreSaleTickesRequest): Promise<PreSaleTickesResponse> {
        const { preSaleTickets } = (
            await this.send({
                query: {
                    preSaleTickets: request
                }
            })
        ).query!

        if (!preSaleTickets) {
            throw newInvalidResponse()
        }

        this.raiseUnexpected(preSaleTickets)

        return preSaleTickets
    }

    public async fetchContacts(request: ChatContactsRequest): Promise<ChatContactsResponse> {
        const { contacts } = (
            await this.send({
                query: {
                    contacts: request
                }
            })
        ).query!

        if (!contacts) {
            throw newInvalidResponse()
        }

        this.raiseUnexpected(contacts)

        return adaptContacts(request, contacts)
    }

    public async fetchTickets(request: ChatTicketsRequest): Promise<ChatTicketsResponse> {
        const { tickets } = (
            await this.send({
                query: {
                    tickets: request
                }
            })
        ).query!

        if (!tickets) {
            throw newInvalidResponse()
        }

        this.raiseUnexpected(tickets)

        return tickets
    }

    public async fetchRoomsToCrm(user: User): Promise<number[]> {
        const { ticketsByUser } = (
            await this.send({
                query: {
                    ticketsByUser: { user }
                }
            })
        ).query!

        if (!ticketsByUser) {
            throw newInvalidResponse()
        }

        this.raiseUnexpected(ticketsByUser)

        return ticketsByUser
    }

    public async fetchContactsAndTickets(
        contactsRequest: ChatContactsRequest,
        ticketsRequest: ChatTicketsRequest
    ): Promise<ContactsAndTicketsResponse> {
        const { contacts, tickets } = (
            await this.send({
                query: {
                    contacts: contactsRequest,
                    tickets: ticketsRequest
                }
            })
        ).query!

        if (!contacts || !tickets) {
            throw newInvalidResponse()
        }

        this.raiseUnexpected(contacts)
        this.raiseUnexpected(tickets)

        return {
            contacts: adaptContacts(contactsRequest, contacts),
            tickets
        }
    }
}

// :: Private static

function newInvalidResponse() {
    return new HttpError(421, 'Server was not able to produce a response')
}

function adaptContacts(request: NS.ChatContactsRequest, contacts: NS.ChatContactsResponse) {
    const convertToClientContact = (rawContact: NS.ChatContact) => {
        const contact = Object.assign({}, rawContact) as unknown as ChatContact
        contact.createdAt = DateFns.parseValidISO(rawContact.createdAt) ?? new Date()
        contact.readAt = DateFns.parseValidISO(rawContact.readAt)
        return contact
    }

    const response: ChatContactsResponse = {
        contacts: [],
        itemsPerPage: contacts.itemsPerPage,
        currentPage: contacts.currentPage,
        numOfUnreadMessages: contacts.numOfReadMessages,
        numOfReadMessages: contacts.numOfUnreadMessages
    }

    for (const entry of contacts.contacts ?? []) {
        const contact = convertToClientContact(entry)
        response.contacts.push(contact)

        if (request.selectedContactId === contact.id) {
            response.selectedContact = contact
        }
    }

    if (!response.selectedContact && contacts.selectedContact) {
        response.selectedContact = convertToClientContact(contacts.selectedContact)
    }

    return response
}
