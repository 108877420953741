import React from 'react'
import ReactDOM from 'react-dom'

import { default as emotionCreateCache } from '@emotion/cache'
import { HostCommunication } from './host-communication'
import { CacheProvider as EmotionCacheProvider, EmotionCache } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { DebouncedFunc, lodash } from '@syonet/lang'
import { EventEmissor } from 'src/types'
import { TssCacheProvider } from 'tss-react/cache'
import reportWebVitals from './reportWebVitals'

import { EurekaService, ServerMessengerService } from './utils/services'
import { ContainerContext } from 'src/utils/views/useContainer'
import { getOrCreateTheme, createEmotionStyleElement } from './theme'
import { App } from './app'
import { useApplicationStore, ApplicationStore } from './aplication-store'
import { handleOnServerMessagerNotification } from './notification.listener'

const eurekaService = EurekaService.singleton()
const serverMessenger = ServerMessengerService.singleton()
const hostCommunication = HostCommunication.singleton()

let registerResources = async () => {
    await eurekaService.postConstruct()
    await serverMessenger.postConstruct()
    registerResources = async () => {}
}

const attributeNames = {
    token: 'token',
    provider: 'provider',
    attendantName: 'attendantName',
    language: 'language',
    intent: 'intent',
    fullPage: 'fullPage',
    fullPageLink: 'fullPageLink'
}

class SyoChatBoat extends HTMLElement {
    // :: Class

    static get observedAttributes() {
        // return an array containing the names of the attributes you want to observe
        return Object.values(attributeNames)
    }

    // :: Instance

    private _reactPlaceEl: HTMLDivElement
    private _refContainerEl: HTMLDivElement
    private _emotionEl: HTMLStyleElement
    private _shadowRoot: ShadowRoot | null
    private _attached: boolean
    private _debouncedRefresh: DebouncedFunc<() => void>
    private _styleCache: EmotionCache | null
    private _eventDispatcher: EventEmissor
    private _unlistenCallbacks: (() => void)[] = []

    constructor() {
        super()
        this._attached = false
        this._shadowRoot = null
        this._styleCache = null
        this._reactPlaceEl = document.createElement('div')
        this._refContainerEl = document.createElement('div')
        this._emotionEl = createEmotionStyleElement()
        this._eventDispatcher = this.doDispatchEvent.bind(this)
        this._debouncedRefresh = lodash.debounce(this.__refresh.bind(this), 150)

        hostCommunication.init(this._eventDispatcher)
    }

    connectedCallback() {
        if (!this._shadowRoot) {
            this._shadowRoot = this.attachShadow({ mode: 'open' })
            this._shadowRoot.appendChild(this._emotionEl)
            this._shadowRoot.appendChild(this._refContainerEl)
            this._styleCache = emotionCreateCache({
                key: 'syo',
                prepend: true,
                container: this._emotionEl
            })
        }

        this._attached = true
        this.__refresh()

        hostCommunication.notifyAttached()
        this._unlistenCallbacks.push(serverMessenger.addOnStatusListener(handleOnServerMessagerNotification))
    }

    disconnectedCallback() {
        if (this._attached) {
            this._attached = false
            if (this._shadowRoot) {
                ReactDOM.unmountComponentAtNode(this._reactPlaceEl)
            }
        }
        hostCommunication.notifyDetached()

        this._unlistenCallbacks.forEach((c) => c())
    }

    adoptedCallback() {
        // called when a Custom Element is moved from one HTML document to another one with the adoptNode() method
        this.__refresh()
    }

    private __refresh() {
        this._debouncedRefresh.cancel()

        const { _shadowRoot, _styleCache, _attached } = this
        if (_shadowRoot && _styleCache && _attached) {
            // Do not use <React.StrictMode> because it causes component contructor
            // being called twice
            const doInitialize = async () => {
                await registerResources()
                let view = <></>

                const token = this.getAttribute(attributeNames.token) ?? undefined
                const provider = this.getAttribute(attributeNames.provider) ?? undefined

                if (token && provider) {
                    const appProps = {} as ApplicationStore

                    appProps.token = token
                    appProps.provider = provider
                    appProps.attendantName = this.getAttribute(attributeNames.attendantName) ?? undefined
                    appProps.fullPage = this.getAttribute(attributeNames.fullPage) === 'true'
                    appProps.fullPageLink = this.getAttribute(attributeNames.fullPageLink) ?? undefined
                    appProps.language = this.getAttribute(attributeNames.language) ?? 'PT-BR'

                    useApplicationStore.setState(appProps)
                    await useApplicationStore.getState().authenticate()

                    view = (
                        <EmotionCacheProvider value={_styleCache}>
                            <TssCacheProvider value={_styleCache}>
                                <ThemeProvider theme={getOrCreateTheme()}>
                                    <ContainerContext.Provider value={this._refContainerEl}>
                                        <App />
                                    </ContainerContext.Provider>
                                </ThemeProvider>
                            </TssCacheProvider>
                        </EmotionCacheProvider>
                    )
                }

                const acceptableRootElm = _shadowRoot as unknown as Element
                ReactDOM.render(ReactDOM.createPortal(view, acceptableRootElm), this._reactPlaceEl)
            }

            doInitialize().catch(console.error)
        }
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (!this._attached) {
            return
        }

        if (oldValue !== newValue) {
            if (name === attributeNames.fullPage) {
                useApplicationStore.setState(() => ({ fullPage: 'true' === newValue }))
                return
            }

            if (name === attributeNames.token) {
                useApplicationStore.setState(() => ({ token: newValue }))
                return
            }

            if (name === attributeNames.provider) {
                useApplicationStore.setState(() => ({ provider: newValue }))
                return
            }

            if (name === attributeNames.fullPageLink) {
                useApplicationStore.setState(() => ({ fullPageLink: newValue }))
                return
            }

            if (name === attributeNames.intent) {
                return
            }
        }
    }

    private doDispatchEvent(event: Event) {
        return super.dispatchEvent(event)
    }

    reply(response: object) {
        if (this._attached) {
            hostCommunication.notifyListeners(response)
        }
    }
}

customElements.define('syo-chat-boat', SyoChatBoat)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
