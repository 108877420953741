import React from 'react'
import { Button } from '@mui/material'
import clsx from 'clsx'
import { useApplicationStore } from 'src/aplication-store'
import MainProvider from '../texts'
import { getOrMakeAlertStyles } from './Alert.styles'

const texts = MainProvider.get()

export type AlertMessageContentViewProps = {
    className?: string
}

export function AlertMessageContentView({ className }: AlertMessageContentViewProps) {
    const [alert, onClose] = useApplicationStore(({ alert, onCloseAlert }) => [alert, onCloseAlert])

    const { classes: styles } = getOrMakeAlertStyles()

    return (
        <div className={clsx(styles.alertMessageContent, className)}>
            <div>{alert?.message}</div>
            <Button color="inherit" onClick={onClose}>
                {texts.ALERT_MESSAGE_CONTENT_CONTINUE_BUTTON}
            </Button>
        </div>
    )
}
