import React from 'react'
import MuiListItemText from '@mui/material/ListItemText'

type ListItemTextProperties = {
    primary: string
    secondary: string
    capitalize?: true
}

export const ListItemText = ({ primary, secondary, capitalize }: ListItemTextProperties) => {
    const properties = {
        primary: capitalize ? primary.toUpperCase() : primary,
        secondary: capitalize ? secondary.toUpperCase() : secondary
    }

    return <MuiListItemText {...properties} />
}

export const ListItemTextMemo = React.memo(ListItemText)
