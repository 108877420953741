import React from 'react'
import { IViewProps } from 'wdc-cube-react'

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

import { ChatContactScope } from '../chat-scope'
import { ListItemTextMemo } from 'src/components/general/ListItemText'
import { useTicketInfo } from 'src/hooks/ticket/info.hook'
import { AvatarInitials } from 'src/components/general/Avatar'

// :: Component(ChatContactItemView)

export type ChatContactItemProps = IViewProps & {
    scope: ChatContactScope
}

export function ChatContactFullItemView({ scope }: ChatContactItemProps) {
    const { ticketInfo, alertColor, statusColor } = useTicketInfo(scope.ticketId)

    return (
        <ListItem disablePadding sx={{ paddingLeft: 1 }} key={scope.id} onClick={scope.onSelect}>
            <ListItemButton disabled={scope.disabled}>
                <ListItemAvatar>
                    <AvatarInitials badgeColor={alertColor} initials={scope.initials} />
                </ListItemAvatar>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-evenly' }}>
                    <Box>
                        <ListItemTextMemo primary={scope.name} secondary={scope.datetime} />
                        <Typography variant="caption">{ticketInfo?.nomeEmpresa}</Typography>
                    </Box>
                    <Box display="flex" gap={1} flexDirection="column">
                        {ticketInfo?.status && (
                            <Chip sx={{ fontSize: 12 }} label={ticketInfo?.status} size="small" color={statusColor} />
                        )}
                        {scope.quantity > 0 ? (
                            <Chip sx={{ alignSelf: 'end' }} label={`${scope.quantity}`} color="error" />
                        ) : undefined}
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    )
}
