export const Texts = {
    CHATS_TITLE: 'Mis conversaciones',
    CHATS_TITLE_CONTENT: 'Conversaciones',
    CHATS_YESTERDAY_LABEL: 'Ayer',
    CHATS_GENERATE_NEW_TICKET_TOOLTIP: 'Generar nuevo evento',
    CHATS_OPEN_TICKET_TOOLTIP: 'Ir al evento',
    CHATS_PER_USER_ROWS_PER_PAGE: 'Por página: ',

    CHATS_FILTER_CUSTOMER_LABEL: 'Cliente',
    CHATS_FILTER_CUSTOMER_PLACEHOLDER: 'Informar a un cliente',
    CHATS_FILTER_CHAT_LABEL: 'Conversacion',
    CHATS_FILTER_DATE_TIME_LABEL: 'Fecha y hora',
    CHATS_FILTER_CHANNEL_LABEL: 'Canales',
    CHATS_FILTER_CLEAR_BUTTON: 'Limpiar',
    CHATS_FILTER_FILTER_BUTTON: 'Filtrar',
    CHATS_FILTER_DATE_TIME_START: 'Fecha/hora de início',
    CHATS_FILTER_DATE_TIME_END: 'Fecha/hora de finalización',
    CHATS_FILTER_STATE_INCONSISTENCY_ALERT_TITLE: 'Inconsistencia del estado',
    CHATS_FILTER_STATE_INCONSISTENCY_ALERT_DESCRIPTION:
        'La fecha de inicio debe ser anterior a la fecha de finalización',
    CHATS_FILTER_SEARCH_WITHOUT_ITEM_ALERT_DESCRIPTION: 'No se permite la búsqueda sin el elemento seleccionado',
    CHATS_FILTER_CHANNEL_CHIP_DESCRIPTION: 'Canal',
    CHAT_FILTER_TICKET_STATUS_ALL: 'TODAS',
    CHAT_FILTER_TICKET_STATUS_HUMAN: 'HUMANO',
    CHAT_FILTER_TICKET_STATUS_ROBOT: 'ROBOT',

    CHATS_FORM_TITLE: 'Nuevo evento',
    CHATS_FORM_CUSTOMER_NAME_LABEL: 'Cliente',
    CHATS_FORM_CUSTOMER_PHONE_LABEL: 'Teléfono',
    CHATS_FORM_COMPANY_NAME_LABEL: 'Agencias',
    CHATS_FORM_TICKET_LABEL: 'Evento',
    CHATS_FORM_SOURCE_LABEL: 'Origen',
    CHATS_FORM_MEDIA_LABEL: 'Medios de comunicación',
    CHATS_FORM_TICKET_OWNER_LABEL: 'Responsable',
    CHATS_FORM_TICKET_RULE_LABEL: 'Respetar la distribución configurada en el evento seleccionado.',
    CHATS_FORM_CANCEL_BUTTON: 'Cancelar',
    CHATS_FORM_GENERATE_TICKET_BUTTON: 'Generar evento',
    CHATS_FORM_TICKET_GENERATED_WITH_SUCCESS: 'Evento generado con éxito',
    CHATS_FORM_TICKET_GENERATION_MISSING_CUSTOMER: 'Necesidad de informar al cliente',
    CHATS_FORM_TICKET_GENERATION_MISSING_COMPANY: 'Necesidad de informar a la empresa',
    CHATS_FORM_TICKET_GENERATION_MISSING_TICKET: 'Informe de evento faltante',
    CHATS_FORM_TICKET_GENERATION_MISSING_SOURCE: 'Falta el origen',
    CHATS_FORM_TICKET_GENERATION_MISSING_MEDIA: 'Necesidad de informar a los medios',
    CHATS_FORM_TICKET_GENERATION_ERROR: 'No se puede generar el evento',

    LOADING_CONTENT_DESCRIPTION: 'Cargando...',
    LOADING_YOUR_CONVERSATIONS: 'Cargando sus mensajes..',
    NO_CHATS_AVAILABLE: 'no hay conversación disponible',
    NO_CHATS_AVAILABLE_TIP: 'filtrar otro período',

    TICKET_COMBO_PLACEHOLDER: 'Atendimentos'
}
