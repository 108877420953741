import io, { Socket } from 'socket.io-client'
import { EurekaService } from './EurekaService'

const eurekaService = EurekaService.singleton()

export interface ServerMessengerEventData {
    phone: string
    event: string
    message: string
    quantity: number
    createdAt: string
}

export type OnServerMessengerConnected = () => unknown
export type OnServerMessengerDisconnected = () => unknown
export type OnServerMessengerStatus = (events: Array<ServerMessengerEventData>) => unknown

export class ServerMessengerService {
    // :: Class

    private static readonly INSTANCE = new ServerMessengerService()

    public static singleton() {
        return ServerMessengerService.INSTANCE
    }

    // :: Fields

    private _socket?: Socket
    private _listenerIdGen = 0
    private _onServerMessengerConnectedMap = new Map<number, OnServerMessengerConnected>()
    private _onServerMessengerDisconnectedMap = new Map<number, OnServerMessengerDisconnected>()
    private _onServerMessengerStatusMap = new Map<number, OnServerMessengerStatus>()

    private constructor() {
        // NOOP
    }

    // :: API

    async postConstruct() {
        if (!this._socket) {
            const socket = (this._socket = io(eurekaService.getSyoWhatsAppBusEndPoint(), {
                path: '/ws3',
                transports: ['websocket'],
                reconnection: true,
                reconnectionDelayMax: 15000
            }))

            socket.on('connect', this.dispatchOnConnectedListeners.bind(this))
            socket.on('disconnect', this.dispatchOnDisconnectedListeners.bind(this))
            socket.on('status', this.dispatchOnStatusListeners.bind(this))
        }
    }

    preDestroy() {
        if (this._socket) {
            this._socket.close()
            this._socket = undefined
        }
    }

    get connected() {
        return this._socket?.connected ?? false
    }

    public close() {
        const socket = this._socket
        if (socket) {
            socket.close()
        }
    }

    private safeOpen() {
        const socket = this._socket
        if (socket) {
            try {
                socket.open()
                return true
            } catch (error) {
                console.warn(error)
            }
        } else {
            console.warn('Service not initialized, so connection is not possible')
        }
        return false
    }

    addOnConnectedListener(listener: OnServerMessengerConnected) {
        const listenerId = this._listenerIdGen++
        this._onServerMessengerConnectedMap.set(listenerId, listener)
        return () => {
            this._onServerMessengerConnectedMap.delete(listenerId)
        }
    }

    private dispatchOnConnectedListeners() {
        for (const listener of this._onServerMessengerConnectedMap.values()) {
            try {
                asyncCatch(listener())
            } catch (error) {
                console.error('dispatchOnConnectedListeners', error)
            }
        }
    }

    addODisconnectedListener(listener: OnServerMessengerConnected) {
        const listenerId = this._listenerIdGen++
        this._onServerMessengerDisconnectedMap.set(listenerId, listener)
        return () => {
            this._onServerMessengerDisconnectedMap.delete(listenerId)
        }
    }

    private dispatchOnDisconnectedListeners() {
        for (const listener of this._onServerMessengerDisconnectedMap.values()) {
            try {
                asyncCatch(listener())
            } catch (error) {
                console.error('dispatchOnDisconnectedListeners', error)
            }
        }
    }

    addOnStatusListener(listener: OnServerMessengerStatus) {
        const listenerId = this._listenerIdGen++
        this._onServerMessengerStatusMap.set(listenerId, listener)
        return () => {
            this._onServerMessengerStatusMap.delete(listenerId)
        }
    }

    private dispatchOnStatusListeners({ events }: { events: Array<ServerMessengerEventData> }) {
        for (const listener of this._onServerMessengerStatusMap.values()) {
            try {
                asyncCatch(listener(events))
            } catch (error) {
                console.error('dispatchOnStatusListeners', error)
            }
        }
    }

    join(domain: string, username: string) {
        const socket = this._socket
        if (socket) {
            this.close()

            const unregister = this.addOnConnectedListener(() => {
                unregister()
                const payload = { id: socket.id, domain, username }
                socket.emit('join', payload)
            })

            this.safeOpen()
        }
    }
}

function asyncCatch(resp: unknown) {
    if (resp) {
        const promise = resp as Promise<unknown>
        if (promise['catch']) {
            promise.catch(console.error)
        }
    }
}
