import create from 'zustand'
import { useApplicationStore } from 'src/aplication-store'
import { HostCommunication } from 'src/host-communication'
import { ChatScope } from './chat-scope'
import { loadChat } from 'src/load-aplication-data'
import { lodash } from '@syonet/lang'

type FullPageControlType = {
    reference?: Window | null
    isOpen: () => boolean
}
const FullPageControl: FullPageControlType = {
    isOpen: function () {
        if (!this.reference) return false
        return !this.reference.closed
    }
}

export const useChatStore = create<ChatScope>((set, get) => ({
    contactsOpened: false,
    isLoading: false,
    entries: [],
    itemCount: 0,
    itemsPerPage: 50,
    pageIndex: 0,
    itemsPerPageOptions: [10, 25, 50, 75, 100],

    header: {
        initials: '',
        name: '',
        phone: '',
        tickets: [],

        onOpenContactList: () => {
            set((state) => ({ contactsOpened: !state.contactsOpened }))
        },

        onTicketSelect: (ticket) => {
            const hostCommunication = HostCommunication.singleton()
            hostCommunication.notifyTicketSelected(ticket)
        }
    },

    whatsapp: {
        customerName: '',
        companyId: null,
        companyPhone: '',
        sendHsm: false,
        userName: '',
        room: '',
        readOnly: false,
        eureka: null,
        customerPhone: '',
        loading: true,
        language: '',
        ready: false,
        error: false
    },

    decoration: {
        caption: 'WhatsApp',
        onClose: function () {
            set(({ whatsapp }) => ({ whatsapp: { ...whatsapp, loading: true } }))
            useApplicationStore.getState().shrinking()
        },
        onExpand: function () {
            const appState = useApplicationStore.getState()
            const fullPageLink = appState.fullPageLink
            if (fullPageLink) {
                let url: URL | null = null

                const startWithProtocolRegex = /^.{0,5}:\/\//
                const hasProtocol = fullPageLink.match(startWithProtocolRegex)

                if (hasProtocol) {
                    url = new URL(fullPageLink)
                } else {
                    const { protocol, host } = location
                    const baseURL = `${protocol}//${host}`
                    url = new URL(baseURL)
                    url.pathname = fullPageLink
                }

                if (FullPageControl.isOpen()) {
                    FullPageControl.reference?.focus()
                } else {
                    FullPageControl.reference = window.open(url.toString())
                }
            }
        }
    },

    updateWhatsapp: function (whatsapp) {
        set((state) => ({
            whatsapp: {
                ...state.whatsapp,
                ...whatsapp
            }
        }))
    },

    onCloseContactList: function () {
        set(() => ({ contactsOpened: false }))
    },

    cleanNotification: function () {
        const state = get()
        const entries = state.entries.map((e) => ({ ...e, quantity: 0 }))
        set(() => ({ entries }))
    },

    onChangeItemsPerPage: lodash.debounce(function (ev) {
        const itemsPerPage = +ev.target.value
        if (Number.isFinite(itemsPerPage)) {
            set(() => ({ itemsPerPage }))
            loadChat()
        }
    }, 250),

    onChangePageIndex: lodash.debounce(function (ev, i) {
        set(() => ({ pageIndex: i }))
        loadChat()
    }, 250),

    updateContacts: function (contacts) {
        set(() => ({ ...contacts }))
    }
}))
