export const Texts = {
    CHATS_TITLE: 'Minhas Conversas',
    CHATS_TITLE_CONTENT: 'Conversas',
    CHATS_YESTERDAY_LABEL: 'Ontem',
    CHATS_GENERATE_NEW_TICKET_TOOLTIP: 'Gerar novo evento',
    CHATS_OPEN_TICKET_TOOLTIP: 'Ir para evento',
    CHATS_PER_USER_ROWS_PER_PAGE: 'Por página: ',

    CHATS_FILTER_CUSTOMER_LABEL: 'Cliente',
    CHATS_FILTER_CUSTOMER_PLACEHOLDER: 'Informe um cliente',
    CHATS_FILTER_CHAT_LABEL: 'Conversa',
    CHATS_FILTER_DATE_TIME_LABEL: 'Data e Hora',
    CHATS_FILTER_CHANNEL_LABEL: 'Canais',
    CHATS_FILTER_CLEAR_BUTTON: 'Limpar',
    CHATS_FILTER_FILTER_BUTTON: 'Filtrar',
    CHATS_FILTER_DATE_TIME_START: 'Data/hora início',
    CHATS_FILTER_DATE_TIME_END: 'Data/hora fim',
    CHATS_FILTER_STATE_INCONSISTENCY_ALERT_TITLE: 'Inconsistência de estado',
    CHATS_FILTER_STATE_INCONSISTENCY_ALERT_DESCRIPTION: 'Data de início deve ser menor que a data de fim',
    CHATS_FILTER_SEARCH_WITHOUT_ITEM_ALERT_DESCRIPTION: 'Busca sem item selecionado não é permitida',
    CHATS_FILTER_CHANNEL_CHIP_DESCRIPTION: 'Canal',
    CHAT_FILTER_TICKET_STATUS_ALL: 'TODAS',
    CHAT_FILTER_TICKET_STATUS_HUMAN: 'HUMANO',
    CHAT_FILTER_TICKET_STATUS_ROBOT: 'ROBÔ',

    CHATS_FORM_TITLE: 'Novo evento',
    CHATS_FORM_CUSTOMER_NAME_LABEL: 'Cliente',
    CHATS_FORM_CUSTOMER_PHONE_LABEL: 'Telefone',
    CHATS_FORM_COMPANY_NAME_LABEL: 'Empresas',
    CHATS_FORM_TICKET_LABEL: 'Evento',
    CHATS_FORM_SOURCE_LABEL: 'Origem',
    CHATS_FORM_MEDIA_LABEL: 'Mídia',
    CHATS_FORM_TICKET_OWNER_LABEL: 'Responsável',
    CHATS_FORM_TICKET_RULE_LABEL: 'Respeitar a distribuição configurada no evento selecionado.',
    CHATS_FORM_CANCEL_BUTTON: 'Cancelar',
    CHATS_FORM_GENERATE_TICKET_BUTTON: 'Gerar evento',
    CHATS_FORM_TICKET_GENERATED_WITH_SUCCESS: 'Evento gerado com sucesso',
    CHATS_FORM_TICKET_GENERATION_MISSING_CUSTOMER: 'Falta informar o cliente',
    CHATS_FORM_TICKET_GENERATION_MISSING_COMPANY: 'Falta informar a empresa',
    CHATS_FORM_TICKET_GENERATION_MISSING_TICKET: 'Falta informar o evento',
    CHATS_FORM_TICKET_GENERATION_MISSING_SOURCE: 'Falta informar a origem',
    CHATS_FORM_TICKET_GENERATION_MISSING_MEDIA: 'Falta informar a mídia',
    CHATS_FORM_TICKET_GENERATION_ERROR: 'Impossível gerar evento',

    LOADING_CONTENT_DESCRIPTION: 'Carregando...',
    LOADING_YOUR_CONVERSATIONS: 'Carregando suas conversas...',
    NO_CHATS_AVAILABLE: 'Nenhuma conversa disponível.',
    NO_CHATS_AVAILABLE_TIP: 'Filtre por outro período',

    TICKET_COMBO_PLACEHOLDER: 'Atendimentos'
}
