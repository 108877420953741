import { HttpError } from '@syonet/lang'
import { HttpClientAPI } from '@whatsapp/communication'
import { AxiosResponse } from 'axios'
import { AxiosService } from './AxiosService'
import { EurekaService } from './EurekaService'

// :: Singletons

const axiosService = AxiosService.singleton()
const eurekaService = EurekaService.singleton()

export class HttpClientService implements HttpClientAPI {
    // :: Class

    private static readonly INSTANCE = new HttpClientService()

    public static singleton() {
        return HttpClientService.INSTANCE
    }

    // :: API

    private reloadAction = () => {
        //window.location.reload()
    }

    get endpoint(): string {
        return eurekaService.getSyoWhatsAppBusEndPoint()
    }

    setReloadAction(reloadAction: () => void) {
        this.reloadAction = reloadAction
    }

    async fetchGet<T>(url: URL, expectedStatusCode: number): Promise<T> {
        let response: AxiosResponse
        try {
            response = await axiosService.getAxios().get(url.toString())
        } catch (caught: unknown) {
            const message = (caught as Error).message
            throw new HttpError(500, message)
        }

        if (response.status !== expectedStatusCode) {
            throw new HttpError(response.status, response.statusText)
        }

        if (response.data.err) {
            throw new HttpError(response.status, `Error ${response.data.err}: ${response.data.msg}`)
        }

        return response.data as T
    }

    async fetchPost<T>(url: URL, expectedStatusCode: number, obj?: unknown): Promise<T> {
        let response: AxiosResponse
        try {
            if (obj ?? false) {
                response = await axiosService.getAxios().post(url.toString(), obj)
            } else {
                response = await axiosService.getAxios().post(url.toString())
            }
        } catch (caught: unknown) {
            const exn = caught as Record<string, unknown>
            if (exn.response) {
                response = exn.response as AxiosResponse
            } else {
                const message = String(exn.message)
                throw new HttpError(500, message || 'Internal Server Error')
            }
        }

        if (response.status === 401) {
            this.reloadAction()
        }

        if (response.status !== expectedStatusCode) {
            throw new HttpError(response.status, response.statusText)
        }

        if (response.data.err) {
            throw new HttpError(response.data.err, `Error ${response.data.err}: ${response.data.msg}`)
        }

        return response.data as T
    }
}
