import { DialogContent, DialogTitle, IconButton } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import clsx from 'clsx'
import React from 'react'
import { getOrMakeAlertStyles } from './Alert.styles'
import { useApplicationStore } from 'src/aplication-store'
import { AlertMessageContentView } from './AlertMessageContent'

const SEVERITY_COLORS: Record<string, React.CSSProperties> = {
    success: { color: '#4CAF50' },
    warning: { color: '#FF9800' },
    info: { color: '#03A9F4' },
    error: { color: '#F44336' }
}

const SEVERITY_BACKGROUND: Record<string, React.CSSProperties> = {
    success: { backgroundColor: SEVERITY_COLORS.success.color },
    warning: { backgroundColor: SEVERITY_COLORS.warning.color },
    info: { backgroundColor: SEVERITY_COLORS.info.color },
    error: { backgroundColor: SEVERITY_COLORS.error.color }
}

export type AlertViewProps = { className?: string }

export function AlertView({ className }: AlertViewProps) {
    const [alert, onClose] = useApplicationStore(({ onCloseAlert, alert }) => [alert, onCloseAlert])

    const { classes: styles } = getOrMakeAlertStyles()

    return !alert ? (
        <></>
    ) : (
        <div className={clsx(styles.alertPane, className)}>
            {onClose && (
                <div className={styles.adjustCloseIcon}>
                    <IconButton onClick={onClose}>
                        <CloseIcon htmlColor="#fff" />
                    </IconButton>
                </div>
            )}
            <DialogTitle
                className={styles.alertBackground}
                style={SEVERITY_BACKGROUND[alert.severity] || SEVERITY_BACKGROUND.info}
            >
                <div className={styles.iconBody}>
                    <div className={styles.backgroundIcon}>
                        {alert.severity === 'success' && (
                            <CheckCircleOutlineIcon className={styles.iconAttributes} style={SEVERITY_COLORS.success} />
                        )}
                        {alert.severity === 'warning' && (
                            <InfoOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.warning} />
                        )}
                        {alert.severity === 'info' && (
                            <InfoOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.info} />
                        )}
                        {alert.severity === 'error' && (
                            <WarningAmberOutlinedIcon className={styles.iconAttributes} style={SEVERITY_COLORS.error} />
                        )}
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.alertTitle}>
                    <strong>{alert.title}</strong>
                </div>
                <div className={styles.alertContent}>
                    <AlertMessageContentView />
                </div>
            </DialogContent>
        </div>
    )
}
