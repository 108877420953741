import React from 'react'

import clsx from 'clsx'
import Badge from '@mui/material/Badge'
import Dialog from '@mui/material/Dialog'
import Fab from '@mui/material/Fab'

import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { useContainer } from 'src/utils/views/useContainer'
import { getOrMakeStyles } from './Main.styles'
import { useApplicationStore } from 'src/aplication-store'
import { AlertView } from './Alert.view'
import { ChatView } from 'src/feature/chat/views'

export const MainView = React.memo(RawMainView)

function RawMainView() {
    const [fullPage, onCloseAlert, alert] = useApplicationStore(({ fullPage, onCloseAlert, alert }) => [
        fullPage,
        onCloseAlert,
        alert
    ])

    const { classes } = getOrMakeStyles()
    const containerEl = useContainer()

    return (
        <div className={clsx(classes.defaultFont, fullPage ? classes.fullView : classes.view)}>
            {!fullPage && <MainBoatBody />}
            {
                <Dialog
                    open={!!alert}
                    onClose={onCloseAlert}
                    aria-labelledby="form-dialog-title"
                    container={containerEl}
                >
                    <AlertView />
                </Dialog>
            }
        </div>
    )
}

type MainBoatBodyProps = {
    className?: string[]
}

function MainBoatBody({ className }: MainBoatBodyProps) {
    const [animating, setAnimating, unreadMessageCount, onExpand, expanded] = useApplicationStore(
        ({ animating, setAnimating, unreadMessageCount, onExpand, expanded }) => [
            animating,
            setAnimating,
            unreadMessageCount,
            onExpand,
            expanded
        ]
    )

    const { classes: cn } = getOrMakeStyles()

    const dialog = React.useMemo(() => {
        let floatButtonClass = ''
        let dialogClass = ''

        const setVisible = (visible: boolean, initialize?: boolean) => {
            let nextBadgeClasses = floatButtonClass
            let nextContentClasses = dialogClass

            if (visible) {
                nextContentClasses = `${cn.contentView} ${cn.visible} ${cn.showAnimation}`
                nextBadgeClasses = `${cn.badge} ${cn.hideAnimation}`
            } else {
                nextBadgeClasses = `${cn.badge} ${cn.visible} ${cn.showAnimation}`
                nextContentClasses = `${cn.contentView} ${cn.hideAnimation}`
            }

            if (nextBadgeClasses !== floatButtonClass || nextContentClasses !== dialogClass) {
                floatButtonClass = nextBadgeClasses
                dialogClass = nextContentClasses
                if (initialize !== true) {
                    setAnimating(true)
                }
            }
        }

        setVisible(!!expanded, true)

        return {
            get floatButtonClass() {
                return floatButtonClass
            },

            get dialogClass() {
                return dialogClass
            },

            setVisible
        }
    }, [expanded, cn])

    const hasContent = expanded
    dialog.setVisible(hasContent)

    const { floatButtonClass, dialogClass } = dialog

    return (
        <>
            <Badge
                className={clsx(animating ? floatButtonClass : hasContent ? cn.hidden : floatButtonClass, className)}
                color={'error'}
                showZero={false}
                badgeContent={unreadMessageCount}
                onClick={onExpand}
                onAnimationEnd={animating ? () => setAnimating(false) : undefined}
            >
                <Fab className={cn.circle} size="medium">
                    <WhatsAppIcon />
                </Fab>
            </Badge>
            <div
                className={animating ? dialogClass : !hasContent ? cn.hidden : dialogClass}
                onAnimationEnd={animating ? () => setAnimating(false) : undefined}
            >
                <ChatView />
            </div>
        </>
    )
}
