type specialConnectives = { [word: string]: boolean }

const connectives: specialConnectives = {
    das: true,
    da: true,
    dos: true,
    do: true,
    de: true,
    e: true
}

export function capitalize(text: string) {
    return text.split(" ").map((word: string) => !connectives[word.toLowerCase()]
        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()).join(" ")
}
