import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { lodash } from '@syonet/lang'

export class AxiosService {
    private static INSTANCE = new AxiosService()

    public static singleton() {
        return AxiosService.INSTANCE
    }

    private constructor() {
        // NOOP
    }

    // :: Provider Property

    private _provider?: string

    public get provider() {
        return this._provider
    }

    public set provider(value: string | undefined) {
        this._provider = value
    }

    // :: Token Property

    private _authorization?: string

    public get authorization() {
        return this._authorization
    }

    public set authorization(value: string | undefined) {
        if (!value) {
            this._authorization = undefined
            return
        }

        const trimValue = lodash.trim(value)
        const parts = trimValue.split(/\s/g)
        if (parts.length === 2) {
            this._authorization = trimValue
        } else if (parts.length === 1 && parts[0] !== 'BASIC') {
            this._authorization = `BASIC ${parts[0]}`
        } else {
            this._authorization = undefined
        }
    }

    public getAxios(config?: AxiosRequestConfig): AxiosInstance {
        const axiosConfig = config || {}

        if (!axiosConfig.headers) {
            axiosConfig.headers = {}
        }

        if (this._provider) {
            axiosConfig.headers['provider-auth'] = this._provider
        }

        if (this._authorization) {
            axiosConfig.headers['authorization'] = this._authorization
        }

        axiosConfig.headers['Access-Control-Allow-Origin'] = '*'

        return Axios.create(axiosConfig)
    }
}

// :: Internals
