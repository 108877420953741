import { makeStyles } from 'tss-react/mui'
import { keyframes } from '@mui/system'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'
import yellow from '@mui/material/colors/yellow'

const CONTACT_LIST_WIDTH = 338

const ShowDrawerBody = keyframes`
    from {background-color: rgba(0, 0, 0, 0.0}
    to {background-color: rgba(0, 0, 0, 0.5}
`
const HideDrawerBody = keyframes`
    from {background-color: rgba(0, 0, 0, 0.5}
    to {background-color: rgba(0, 0, 0, 0.0}
`

const ShowContactBar = keyframes`
    from {left: -${CONTACT_LIST_WIDTH}px}
    to {left: 0}
`

const HideContactBar = keyframes`
    from {left: 0}
    to {left: -${CONTACT_LIST_WIDTH}px}
`

const Roboto = ['Roboto', 'sans-serif'].join(',')

export const getOrMakeStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#EEEEEE',
        flexGrow: 1,
        flexShrink: 1,
        alignSelf: 'stretch',

        borderRadius: 4,
        boxShadow: [
            '0px 7px 8px -4px rgba(0, 0, 0, 0.2)',
            '0px 12px 17px 2px rgba(0, 0, 0, 0.14)',
            '0px 5px 22px 4px rgba(0, 0, 0, 0.12)'
        ].join(', ')
    },
    noContentAlert: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        columnGap: 10,
        padding: 20,
        color: 'rgba(0, 0, 0, .6)',
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${yellow[800]}`,
        '& .icon': {
            color: yellow[800]
        },
        '& .rows': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    chatContainer: {
        display: 'flex',
        flexDirection: 'row',
        border: '3px solid #EEEEEE',
        borderRadius: 4,
        height: '100vh',
        flexGrow: 1
    },
    contactContainer: {
        borderRight: '1px solid #E0E0E0',
        display: 'flex',
        flexDirection: 'column',
        width: CONTACT_LIST_WIDTH
    },
    chatBody: {
        alignSelf: 'stretch',
        flexGrow: 1,
        borderRadius: '0px 0px 4px 0px',
        zIndex: 1,
        flexBasis: 200
    },
    body: {
        position: 'relative',
        display: 'flex',
        flexShrink: 1,
        flexGrow: 1,
        flexDirection: 'column',
        overflowX: 'hidden'
    },
    drawer: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '0px 0px 4px 4px'
    },
    drawerOpened: {
        animation: `${ShowDrawerBody} 0.225s`,
        animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
    },
    drawerClosing: {
        animation: `${HideDrawerBody} 0.195s`,
        animationTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    drawerClosed: {
        display: 'none'
    },
    contactBarShowIn: {
        animation: `${ShowContactBar} 0.225s`,
        animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
    },
    contactBarHideOut: {
        animation: `${HideContactBar} 0.195s`,
        animationTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)'
    }
})

export const getOrMakeDecorationStyles = makeStyles()({
    header: {
        minHeight: 24,
        backgroundColor: green[600],
        borderRadius: '4px 4px 0px 0px',

        padding: '8px 8px 8px 16px',
        gap: '4px',
        justififyContent: 'center',
        color: 'white',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    hSpacer: {
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        alignSelf: 'stretch'
    },
    headerButton: {
        color: 'white'
    }
})

export const getOrMakeHeaderStyles = makeStyles()((theme) => ({
    view: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: -1,
        right: -1,
        marginLeft: -1,
        height: 64,
        backgroundColor: '#EEEEEE',
        paddingRight: theme.spacing(1)
    },
    appBarTitle: {
        flexGrow: 1
    },
    appBarMenuButton: {
        marginLeft: 6
    },
    contactHeaderImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 40,
        minWidth: 40,
        maxHeight: 40,
        minHeight: 40,
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 20,
        letterSpacing: 0.14,
        backgroundColor: grey[400],
        borderRadius: '50%',
        color: '#fff',
        margin: '12px 16px 12px 4px'
    },
    adjustContactAndActionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    contactNameContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactNameLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15
    },
    contactPhoneLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.38)'
    },
    adjustActionButton: {
        paddingRight: 16
    }
}))

export const getOrMakeContactsStyles = makeStyles()((theme) => ({
    contactListView: {
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        maxWidth: CONTACT_LIST_WIDTH,
        flexDirection: 'column',
        backgroundColor: 'white',
        '& .MuiList-root': {
            overflowY: 'auto',
            overflowX: 'hidden',
            flexShrink: 1,
            flexGrow: 1,
            alignSelf: 'stretch',
            flexBasis: '64px'
        },
        '& .MuiTablePagination-root': {
            overflow: 'hidden',

            '& .MuiTablePagination-spacer': {
                display: 'none'
            },
            '& .MuiInputBase-root': {
                margin: 0
            },
            '& .MuiToolbar-root': {
                paddingLeft: 18
            }
        }
    },
    contactListViewTitle: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    customTablePagination: {
        '.MuiTablePagination-selectLabel': {
            fontFamily: ['Roboto', 'sans-serif'],
            fontStyle: 'normal',
            fontWeight: 400,
            letterSpacing: 0.4,
            color: theme.palette.text.secondary
        }
    }
}))

export const getOrMakeChatMenuItemStyles = makeStyles()(() => ({
    contactItemImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 30,
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.14,
        backgroundColor: grey[400],
        borderRadius: '50%',
        color: '#fff',
        margin: '12px 16px 12px 0px'
    },
    contactLastMessageDateTimeLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0.4,
        color: 'rgba(0, 0, 0, 0.38)'
    },
    adjustContactAndActionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    contactNameContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    contactNameLabel: {
        fontFamily: Roboto,
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.15
    },
    robotIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        paddingRight: 6
    }
}))
