import React from 'react'
import styled from '@mui/system/styled'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import MenuItem from '@mui/material/MenuItem'
import CircleIcon from '@mui/icons-material/Circle'
import { TicketItemValue } from '../chat-scope'
import { useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { useTicketInfo } from 'src/hooks/ticket/info.hook'

const TicketMenuItemBase = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1)
}))

const Dot = styled(CircleIcon)(({ theme }) => {
    return {
        width: 5,
        height: 5,
        color: theme.palette.text.secondary
    }
})

type Props = {
    ticket: TicketItemValue
    onClick(id: number): void
}

export const TicketMenuItem = ({ ticket, onClick }: Props) => {
    const handleMenuItemClicked = useCallback(() => onClick(ticket.id), [ticket.id])
    const { alertColor, ticketInfo } = useTicketInfo(ticket.id)

    const grupoAndStatus = !ticketInfo ? ticket.text : `${ticket.text.split('/')[0]}/${ticketInfo.status}`

    return (
        <TicketMenuItemBase key={ticket.id} onClick={handleMenuItemClicked}>
            <Typography variant="subtitle1">{ticket.id}</Typography>
            <Dot />
            <Typography variant="subtitle2">{grupoAndStatus}</Typography>
            <Dot />
            <AccessAlarmIcon sx={{ m: 'auto' }} color={alertColor} fontSize="small" />
        </TicketMenuItemBase>
    )
}
