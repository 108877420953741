/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AbstractBusClientService } from 'src/utils/services'
import { ChatBoatMain as NS } from '@whatsapp/communication'

export type AuthorizedUser = NS.AuthorizedUser

export class MainService extends AbstractBusClientService {
    // :: Static

    private static INSTANCE = new MainService()

    public static singleton() {
        return MainService.INSTANCE
    }

    // :: API

    private send = this.newSend<NS.Request, NS.Response>(NS.PATHS.base)

    public async authorize() {
        const lang = navigator.language

        const { authorize } = (
            await this.send({
                query: {
                    authorize: { lang }
                }
            })
        ).query!

        this.raiseUnexpected(authorize)

        return authorize!.user!
    }
}
