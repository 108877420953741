import { differenceInDays, differenceInHours } from 'date-fns'
import { useApplicationStore } from 'src/aplication-store'
import useSWR from 'swr'
import axios from 'axios'
import type { Color } from 'src/theme'

type Info = {
    dataProximaAcao: number
    status: 'ANDAMENTO' | 'AGUARDANDO' | 'SUCESSO' | 'INSUCESSO'
    nomeEmpresa: string
    periodoTempoLimite: 'DIA' | 'HORA'
    tempoLimite: number
}

export const useTicketInfo = (ticketId: number) => {
    const [token] = useApplicationStore(({ provider, token }) => [provider, token])
    const baseURL = `${location.protocol}//${location.host}/api`

    const invalid = !token

    const { data, isLoading } = useSWR<Info>(
        invalid ? null : [`${baseURL}/evento/${ticketId}/flyweight`, token],
        ([path, token]: [string, string]) => {
            return axios
                .get(path, {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => res.data)
        },
        { refreshInterval: 5000 }
    )

    const alertColor = getTicketAlertColor(data)
    const statusColor = getTicketStatusColor(data)
    const ticketInfo = data

    return { ticketInfo, isLoading, alertColor, statusColor }
}

function getTicketAlertColor(info?: Pick<Info, 'periodoTempoLimite' | 'tempoLimite' | 'dataProximaAcao'>): Color {
    const yellow = 'warning'
    const red = 'error'
    const green = 'success'

    if (!info) return green

    const tempoLimite = info.tempoLimite
    const periodoTempoLimite = info.periodoTempoLimite
    const isHours = periodoTempoLimite === 'HORA'

    const now = new Date()
    const nextAction: Date = new Date(info.dataProximaAcao)

    const diffInDays = differenceInDays(nextAction, now)

    if (diffInDays === 0) {
        if (isHours) {
            const diffInHour = differenceInHours(nextAction, now)
            if (isLate(diffInHour, tempoLimite)) {
                return red
            } else {
                return yellow
            }
        } else {
            return yellow
        }
    }

    if (diffInDays < 0) {
        if (isHours) {
            return red
        }

        if (isLate(diffInDays, tempoLimite)) {
            return red
        } else {
            return yellow
        }
    }

    return green
}

function getTicketStatusColor(info?: Pick<Info, 'status'>): Color {
    if (info?.status === 'AGUARDANDO') return 'primary'
    if (info?.status === 'ANDAMENTO') return 'info'
    if (info?.status === 'SUCESSO') return 'secondary'
    if (info?.status === 'INSUCESSO') return 'error'
    return 'primary'
}

function isLate(diff: number, limit: number) {
    const result = diff + limit
    if (result < 0) {
        return true
    }
    return false
}
