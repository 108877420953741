import { createTheme as muiCreateTheme } from '@mui/material'
import { LocaleProvider } from './utils/views'

export type Color = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

export const createEmotionStyleElement = () => {
    const sheet = document.createElement('style')
    sheet.innerHTML = [
        '*::-webkit-scrollbar { width: 0.4em; }',
        '*::-webkit-scrollbar-thumb { border-radius: 0.5rem; background-color: #43A047; }',
        '*::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%); }'
    ].join('\n')
    return sheet
}

export const getOrCreateTheme = (() => {
    let getTheme = () => {
        const locale = LocaleProvider.get()

        const theme = muiCreateTheme(
            {
                typography: {
                    caption: {
                        color: 'rgba(0, 0, 0, 0.38)'
                    }
                },
                palette: {
                    primary: {
                        main: '#606060',
                        dark: '#363636',
                        light: '#8D8D8D',
                        contrastText: '#FFFFFF'
                    },
                    secondary: {
                        main: '#43A047',
                        dark: '#00701A',
                        light: '#76D275',
                        contrastText: '#FFFFFF'
                    },
                    text: {
                        primary: '#000000DE',
                        secondary: '#0000008A',
                        disabled: '#00000061'
                    },
                    action: {
                        hover: '#E0E0E0',
                        selected: '#00000014',
                        disabledBackground: '#0000001F'
                    },
                    info: {
                        main: '#2196F3',
                        dark: '#0B79D0',
                        light: '#64B6F7'
                    },
                    error: {
                        main: '#F44336',
                        dark: '#E31B0C',
                        light: '#F88078'
                    },
                    warning: {
                        main: '#FF9800',
                        dark: '#C77700',
                        light: '#FFB547'
                    },
                    success: {
                        main: '#4CAF50',
                        dark: '#3B873E',
                        light: '#7BC67E'
                    },
                    divider: '#E0E0E0'
                }
            },
            locale
        )

        getTheme = () => theme

        return theme
    }

    return getTheme
})()
