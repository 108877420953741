import React from 'react'
import { useApplicationStore } from './aplication-store'
import { ChatView } from './feature/chat/views'
import { MainView } from './feature/main/views'
import { HostCommunication } from './host-communication'

export function App() {
    const [user, fullPage] = useApplicationStore(({ user, fullPage }) => [user, fullPage])

    React.useEffect(() => {
        if (fullPage) {
            const hostCommunication = HostCommunication.singleton()
            hostCommunication.requestTickets(user!)
        }
    }, [fullPage])

    return !user?.username ? <></> : fullPage ? <ChatView /> : <MainView />
}
