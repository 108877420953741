import React from 'react'
import styled from '@mui/system/styled'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import { ListItemTextMemo } from 'src/components/general/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'

import { useContainer } from 'src/utils/views/useContainer'
import { TextsProvider } from '../texts'
import { useChatStore } from '../chat-store'

import { getOrMakeHeaderStyles } from './Chat.styles'
import { useApplicationStore } from 'src/aplication-store'
import { TicketMenuItem } from './TicketMenuItem.view'

const texts = TextsProvider.get()

// :: Component(ChatHeaderView)

const GrayButton = styled(Button)(({ theme }) => {
    return {
        color: theme.palette.text.secondary
    }
})

export function ChatHeaderView() {
    const scope = useChatStore((store) => store.header)
    const fullpage = useApplicationStore((store) => store.fullPage)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = React.useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleMenuItemClicked = React.useCallback(
        (ticketId: number) => {
            setAnchorEl(null)
            scope.onTicketSelect(ticketId)
        },
        [scope]
    )

    const { classes } = getOrMakeHeaderStyles()
    const containerEl = useContainer()

    return (
        <div className={classes.view}>
            {!fullpage && (
                <IconButton
                    edge="start"
                    className={classes.appBarMenuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={scope.onOpenContactList}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <ListItem>
                <ListItemAvatar>
                    <Avatar>{scope.initials}</Avatar>
                </ListItemAvatar>
                <ListItemTextMemo primary={scope.name} secondary={scope.phone}></ListItemTextMemo>
            </ListItem>
            {scope.tickets.length > 0 && (
                <>
                    <Typography component="div" color="textSecondary">
                        <GrayButton
                            variant="text"
                            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            {texts.TICKET_COMBO_PLACEHOLDER}
                        </GrayButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} container={containerEl}>
                            {scope.tickets.map((ticket) => (
                                <TicketMenuItem key={ticket.id} onClick={handleMenuItemClicked} ticket={ticket} />
                            ))}
                        </Menu>
                    </Typography>
                </>
            )}
        </div>
    )
}
