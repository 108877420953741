import React from 'react'

import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { useContainer } from 'src/utils/views/useContainer'
import { TextsProvider } from '../texts'
import { useChatStore } from '../chat-store'

import { getOrMakeContactsStyles } from './Chat.styles'
import { ChatContactItemView } from './ChatContactItem.view'

import { useApplicationStore } from 'src/aplication-store'
import { ChatContactFullItemView } from './ChatContactFullItem.view'

const texts = TextsProvider.get()

// :: Component(ChatContactsView)

export type PageChangeEventHandler = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void

export type ChatContactsViewProps = {
    className?: string
    onAnimationEnd: (event: React.AnimationEvent<HTMLDivElement>) => void
}

export function ChatContactsView({ className, onAnimationEnd }: ChatContactsViewProps) {
    const scope = useChatStore()
    const [fullScreen, blockContactChange] = useApplicationStore(({ fullPage, blockContactChange }) => [
        fullPage,
        blockContactChange
    ])

    const { classes, cx } = getOrMakeContactsStyles()
    const containerEl = useContainer()

    const itemCount = scope.itemCount || 0
    const itemsPerPage = scope.itemsPerPage || 0
    const pageIndex = scope.pageIndex
    const backNextIconDisplacement = scope.itemsPerPage < 100 ? -10 : -15

    const onChangePageIndex = scope.onChangePageIndex as unknown as PageChangeEventHandler
    const onChangeItemsPerPage = scope.onChangeItemsPerPage

    React.useEffect(() => {
        window.addEventListener(
            'message',
            (event) => {
                if (event.data.block && !blockContactChange) {
                    setTimeout(() => useApplicationStore.getState().setBlockContactChange(false), 30000)
                }

                useApplicationStore.getState().setBlockContactChange(event.data.block)
            },
            false
        )
    }, [])

    return (
        <Box className={cx(classes.contactListView, className)} onAnimationEnd={onAnimationEnd}>
            <Typography variant="h6" gutterBottom component="div" className={classes.contactListViewTitle}>
                {texts.CHATS_TITLE_CONTENT}
            </Typography>
            <List dense disablePadding>
                {scope.entries.map((contactScope) =>
                    fullScreen ? (
                        <ChatContactFullItemView
                            key={contactScope.id}
                            scope={{
                                ...contactScope,
                                disabled: blockContactChange,
                                ...(blockContactChange && { onSelect: () => false })
                            }}
                        />
                    ) : (
                        <ChatContactItemView
                            key={contactScope.id}
                            scope={{
                                ...contactScope,
                                disabled: blockContactChange,
                                ...(blockContactChange && { onSelect: () => false })
                            }}
                        />
                    )
                )}
            </List>
            <TablePagination
                rowsPerPageOptions={scope.itemsPerPageOptions}
                component="div"
                size="small"
                count={itemCount}
                rowsPerPage={itemsPerPage}
                page={pageIndex}
                onPageChange={onChangePageIndex}
                onRowsPerPageChange={onChangeItemsPerPage}
                labelRowsPerPage={texts.CHATS_PER_USER_ROWS_PER_PAGE}
                SelectProps={{
                    MenuProps: {
                        container: containerEl
                    }
                }}
                backIconButtonProps={{
                    sx: { margin: 0, padding: 0, left: backNextIconDisplacement }
                }}
                nextIconButtonProps={{
                    sx: { margin: 0, padding: 0, left: backNextIconDisplacement }
                }}
                className={classes.customTablePagination}
            />
        </Box>
    )
}
