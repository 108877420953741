import React from 'react'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import AccessAlarm from '@mui/icons-material/AccessAlarm'

import type { Color } from 'src/theme'

type Props = {
    initials: string
    badgeColor: Color
}

export function AvatarInitials(props: Props) {
    return (
        <Badge
            componentsProps={{ badge: { style: { borderRadius: '50%', height: 24, width: 24 } } }}
            overlap="rectangular"
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            badgeContent={<AccessAlarm sx={{ fontSize: 16 }} />}
            color={props.badgeColor}
        >
            <Avatar>{props.initials}</Avatar>
        </Badge>
    )
}
