import type { FlagMessageDTO, ChatBoatChat as NS } from '@whatsapp/communication'
import { useApplicationStore } from './aplication-store'
import { useChatStore } from './feature/chat/chat-store'
import { ChatService } from './feature/chat/Chat.service'
import { EventEmissor } from './types'

type ReplyRecord = Record<string, unknown>
type PageRequestHandler = (request: ReplyRecord) => void
type User = { domain: string; username: string }

export class HostCommunication {
    private static readonly INSTANCE = new HostCommunication()

    private _eventEmissor: EventEmissor = () => false
    private _actionHandlerIdGen = 0
    private _actionHandlerMap = new Map<number, PageRequestHandler>()
    private _chatService = ChatService.singleton()

    public static singleton() {
        return HostCommunication.INSTANCE
    }

    init(eventEmissor: EventEmissor) {
        this._eventEmissor = eventEmissor
        this._actionHandlerMap.clear()

        this.addListener(onTicketReply)
        this.addListener(onTemplateFieldReply)
    }

    addListener(handler: PageRequestHandler) {
        const handleId = this._actionHandlerIdGen++
        this._actionHandlerMap.set(handleId, handler)
        return () => {
            this._actionHandlerMap.delete(handleId)
        }
    }

    notifyListeners(response: unknown) {
        try {
            const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response
            for (const handler of this._actionHandlerMap.values()) {
                handler(parsedResponse)
            }
        } catch (error) {
            console.error(error)
        }
    }

    public notifyAttached() {
        this._eventEmissor(new Event('attached'))
    }

    public notifyDetached() {
        this._eventEmissor(new Event('detached'))
    }

    public notifyTicketSelected(ticketId: number) {
        const event = new Event('ticketselect', {
            bubbles: false
        })
        Object.defineProperty(event, 'target', { writable: false, value: { value: ticketId } })
        this._eventEmissor(event)
    }

    public notifyCustomerChanged(room: string, ticketId: number) {
        const event = new Event('customerchange', {
            bubbles: false
        })
        Object.defineProperty(event, 'target', { writable: false, value: { room, ticketId } })
        this._eventEmissor(event)
    }

    public requestTickets(user: User) {
        useApplicationStore.setState(() => ({ loadingTickets: true }))

        const event = new Event('request-tickets', {
            bubbles: false
        })
        this._chatService.fetchRoomsToCrm(user).then((tickets) => {
            Object.defineProperty(event, 'target', { writable: false, value: { tickets } })
            this._eventEmissor(event)
        })
    }

    public messageSent(evt: Event) {
        const customEvent = evt as CustomEvent<FlagMessageDTO>
        const event = new Event(evt.type)
        Object.defineProperty(event, 'detail', { writable: false, value: customEvent.detail })
        this._eventEmissor(event)
    }
}

function onTemplateFieldReply(record: ReplyRecord) {
    if (record.name === 'templateFields') {
        const body = record.body as {
            room: string
            templateFields: unknown
        }

        if (body.templateFields) {
            const templateFields = JSON.stringify(body.templateFields)

            useChatStore.getState().updateWhatsapp({
                loading: false,
                sendHsm: true,
                templateFields: templateFields
            })
        }
    }
}

function onTicketReply(record: ReplyRecord) {
    const { name, body } = record
    if (name !== 'tickets') {
        return
    }
    const tickets = body as NS.Ticket[]
    useApplicationStore.getState().onTicketReply(tickets)
}
