import React from 'react'

import clsx from 'clsx'
import IconButton from '@mui/material/IconButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CloseIcon from '@mui/icons-material/Close'

import { useChatStore } from '../chat-store'
import { getOrMakeDecorationStyles } from './Chat.styles'
import { Typography } from '@mui/material'
import { TextsProvider } from '../texts'

// :: Component(ChatDecorationView)

const texts = TextsProvider.get()

export type ChatDecorationProps = {
    className?: string
}

export function ChatDecorationView({ className }: ChatDecorationProps) {
    const scope = useChatStore((store) => store.decoration)

    const { classes } = getOrMakeDecorationStyles()

    return !scope ? (
        <></>
    ) : (
        <header className={clsx(classes.header, className)}>
            <Typography fontSize={16}>{texts.CHATS_TITLE}</Typography>
            <span className={classes.hSpacer} />
            <IconButton className={classes.headerButton} onClick={scope.onExpand}>
                <OpenInNewIcon />
            </IconButton>
            <IconButton className={classes.headerButton} onClick={scope.onClose}>
                <CloseIcon />
            </IconButton>
        </header>
    )
}
