export const Texts = {
    DATE_FORMAT: 'dd/MM/yyyy',
    DATE_HH_FORMAT: 'dd/MM/yyyy HH',
    DATE_HH_MM_FORMAT: 'dd/MM/yyyy HH:mm',
    DATE_HH_MM_SS_FORMAT: 'dd/MM/yyyy HH:mm:ss',
    TIME_FORMAT: 'HH:mm',

    ALERT_MESSAGE_CONTENT_CONTINUE_BUTTON: 'Fechar',

    ALERT_NON_AUTENTICATED_TILE: 'Usuário não autenticado',
    ALERT_NON_AUTENTICATED_MESSAGE: 'A configuração atual não permite autenticação',

    NO_MESSAGE: 'Sem mensagem para exibir'
}
